import React, { useEffect, useState } from "react";
import { formatDateToDay, getThisYear, stringToDate } from "../../../utils";
import { TR } from "../../../utils/helpers";
import ServerSelect from '../React-Select-Server';
import ReactDatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import MaskedInput from 'react-text-mask'
import { autoCorrectedDatePipe } from '../../../utils/index';
import MultiSelect from "../MultiSelect";
import Region from '../../../services/cruds/RegionService';
import District from '../../../services/cruds/DistrictService';

const NewSearchFilter = (props) => {
    const {
        toggle,
        setToggle,
        date,
        api_list,
        handleSearch,
        defaultList,
        selectedCheckbox,
        dataIDList,
        dataIdOptions,
        lastUpdateDate,
        productTypes,
        dataType,
        others,
        lang
    } = props;
    const [ids, setIds] = useState({ ...defaultList });
    const [list, setList] = useState({ ...defaultList, dtID: [...productTypes] });
    const country_id = 19;
    const [regionId, setRegionId] = useState(null);
    const [districtId, setDistrictId] = useState(null);
    const [tempDataType, setTempDataType] = useState(dataType);
    const [tempSelectedCheckbox, setTempSelectedCheckbox] = useState(selectedCheckbox);
    const [listDistrict, setListDistrict] = useState([]);
    const [listRegion, setListRegion] = useState([]);
    const [selectedList, setSelectedList] = useState({ ...defaultList });
    const [loadingDistrict, setLoadingDistrict] = useState(false);
    const [loadingRegion, setLoadingRegion] = useState(false);
    const [loading, setLoading] = useState({
        drugID: false,
        distID: false,
        companyID: false,
        mfID: false,
        dfID: false,
        dfgID: false,
        innID: false,
        dtgID: false,
        trademarkID: false,
        countryID: false,
    });
    const [datePicker, setDatePicker] = useState(date);
    const [timer, setTimer] = useState(null);
    const handleChange = (value, index, id) => {
        const DATA = [...datePicker];
        DATA[index][id] = formatDateToDay(value);
        setDatePicker(DATA);
    }
    const handleChangeSelect = (e, key) => {
        setSelectedList(data => {
            data[key] = e || [];
            return { ...data }
        })
        const tempIds = e?.map(key => key.value) || [];
        setIds(data => {
            data[key] = [...tempIds];
            return { ...data }
        })
    }
    const handleAdd = () => {
        setDatePicker([...datePicker, { fromDate: null, toDate: null }])
    }

    const handleDelete = (index) => {
        const DATA = [];
        datePicker.forEach((key, i) => {
            if (i !== index) DATA.push(key);
        })
        setDatePicker(DATA);
    }

    const handleClear = (index) => {
        const DATA = [...datePicker];
        DATA[index] = { fromDate: null, toDate: null };
        setDatePicker([...DATA]);
    }

    const handleChangeProductTypesSelect = (e) => {
        setSelectedList(data => {
            data['drugID'] = [];
            data['dtID'] = e || [];
            return { ...data }
        })
        const tempIds = e?.map(key => key.value) || [];
        setIds(data => {
            data['drugID'] = [];
            data['dtID'] = [...tempIds];
            return { ...data }
        });
        setList(data => {
            data['drugID'] = [];
            return { ...data }
        })
    }

    const filterDb = (arr_key, API, value, index, additional) => {
        const new_list = [];
        setLoading(data => {
            data[arr_key] = true;
            return { ...data }
        });
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            if (API.name === "drug") {
                API.search(value, ids).then((res) => {
                    res.data.data.forEach(key => { if (!ids[arr_key].includes(key.id)) new_list.push({ value: key.id, label: key.name || "" }) })
                    setList(data => {
                        data[arr_key] = [...selectedList[arr_key], ...new_list]
                        return { ...data }
                    });
                    setLoading(data => { data[arr_key] = false; return { ...data } });
                })
            } else {
                if (arr_key === 'region') {
                    setLoadingRegion(true);
                    API.select(true, false, value, additional).then((res) => {
                        res.data.data.forEach(key => { if (regionId !== key.id) new_list.push({ value: key.id, label: key.full_name || "" }) })
                        setListRegion(prev => {
                            return [...prev, ...new_list]
                        })

                        setLoadingRegion(false)
                    })
                } else if (arr_key === 'district') {
                    setLoadingDistrict(true)
                    API.select(true, false, value, additional).then((res) => {
                        res.data.data.forEach(key => { if (districtId !== key.id) new_list.push({ value: key.id, label: key.full_name || "" }) })
                        setListDistrict(prev => {
                            return [...prev, ...new_list]
                        })
                        setLoadingDistrict(false)
                    })
                } else {
                    API.select(true, false, value, ids).then((res) => {
                        res.data.data.forEach(key => { if (!ids[arr_key].includes(key.id)) new_list.push({ value: key.id, label: key.full_name || "" }) })
                        setList(data => {
                            data[arr_key] = [...selectedList[arr_key], ...new_list]
                            return { ...data }
                        });
                        setLoading(data => { data[arr_key] = false; return { ...data } });
                    })
                }
            }
        }, 1000)
        setTimer(newTimer);
    };

    useEffect(() => {
        setTempDataType(dataType)
        if (toggle) {
            setRegionId(others?.region_id || null);
            setDistrictId(others?.district_id || null);
            setListRegion(others?.listRegion || []);
            setListDistrict(others?.listDistrict || [])
            setIds({ ...dataIDList });
            setList({ ...dataIdOptions });
            setDatePicker(date);
            setSelectedList({ ...dataIdOptions });
            setTempSelectedCheckbox(selectedCheckbox)
        } else {
            setRegionId(null);
            setDistrictId(null);
            setListRegion([]);
            setListDistrict([])
            setTempSelectedCheckbox(selectedCheckbox)
            setSelectedList({ ...defaultList });
            setIds({ ...defaultList });
            setList({ ...defaultList, dtID: [...productTypes] });
            setDatePicker([getThisYear(lastUpdateDate)]);
        }
    }, [toggle])

    return (
        <>
            <div className={`sidebar-right media-width ${toggle ? "show" : ""}`}>
                <div className="bg-overlay" onClick={() => setToggle(!toggle)}></div>
                <div className="sidebar-right-inner media-width p-4" style={{ overflowY: "auto", maxHeight: "600px" }}>
                    <div className="mt-3">
                        <h6 className="mb-2">{TR(lang, "products.analysis_type")}</h6>

                        <div className="d-flex align-items-center gap-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="options"
                                    id="option1"
                                    value={1}
                                    checked={tempDataType === 1}
                                    onChange={(e) => setTempDataType(Number(e.target.value))}
                                />
                                <label className="form-check-label" htmlFor="option1">PRIXOD</label>
                            </div>

                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="options"
                                    id="option2"
                                    value={2}
                                    checked={tempDataType === 2}
                                    onChange={(e) => setTempDataType(Number(e.target.value))}
                                />
                                <label className="form-check-label" htmlFor="option2">PRODAJA</label>
                            </div>
                        </div>
                        {
                            tempDataType === 2 ?
                                <>
                                    <div className="form-group mb-3 col-md-6">
                                        <label className='black-font' htmlFor='region'>{TR(lang, "products.region")}</label>
                                        <ServerSelect
                                            id="region"
                                            API={Region}
                                            arr_key='region'
                                            options={listRegion}
                                            onChange={e => {
                                                if (e.value !== regionId) {
                                                    setRegionId(e.value)
                                                    setDistrictId(null)
                                                    setListDistrict([])
                                                }
                                            }}
                                            value={listRegion.filter(key => key.value === regionId)}
                                            isLoading={loadingRegion}
                                            filterDb={filterDb}
                                            additional={{ country_id }}
                                            placeholder={TR(lang, "products.region")}
                                        />
                                    </div>
                                    {
                                        regionId ?
                                            <div className="form-group mb-3 col-md-6">
                                                <label className='black-font' htmlFor='district'>{TR(lang, "products.district")}</label>
                                                <ServerSelect
                                                    id="district"
                                                    API={District}
                                                    arr_key='district'
                                                    options={listDistrict}
                                                    onChange={e => setDistrictId(e.value)}
                                                    value={listDistrict.filter(key => key.value === districtId)}
                                                    isLoading={loadingDistrict}
                                                    filterDb={filterDb}
                                                    additional={{ region_id: regionId }}
                                                    placeholder={TR(lang, "products.district")}
                                                />
                                            </div>
                                            : null
                                    }
                                </>
                                : null
                        }
                    </div>
                    <div className="row mb-2">
                        <h6> {TR(lang, "products.dt")}</h6>
                        <MultiSelect
                            placeholder="products.dt"
                            onChange={e => handleChangeProductTypesSelect(e)}
                            value={list.dtID.filter(key => ids.dtID.includes(key.value))}
                            options={list.dtID}
                        />
                    </div>
                    <div className="row">
                        {
                            api_list.map(element => {
                                const is_active = tempSelectedCheckbox === element.checkbox_key;
                                return (
                                    <div key={element.key} className="col-md-6">
                                        <h6 className="m-0">{element.title}</h6>
                                        <div className="row">
                                            <input
                                                className="col-md-1"
                                                type="checkbox"
                                                checked={is_active}
                                                onChange={() => {
                                                    setTempSelectedCheckbox(element.checkbox_key);
                                                }}
                                            />
                                            <ServerSelect
                                                className="col-md-11"
                                                API={element.api}
                                                arr_key={element.key}
                                                options={list[element.key]}
                                                onChange={e => handleChangeSelect(e, element.key)}
                                                isMulti
                                                value={list[element.key].filter(key => ids[element.key].includes(key.value))}
                                                isLoading={loading[element.key]}
                                                filterDb={filterDb}
                                                required
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='my-2'>
                        {
                            datePicker.map((key, index) =>
                                <div key={index} className='my-3'>
                                    <h6 className='text-nowrap me-2'> {index + 1} -  {TR(lang, "content.period")}:</h6>
                                    <div className="d-flex">

                                        <ReactDatePicker
                                            showYearDropdown
                                            showMonthDropdown
                                            dropdownMode="select"
                                            className="form-control form-control-sm"
                                            onSelect={e => handleChange(e, index, 'fromDate')}
                                            onChange={e => handleChange(e, index, 'fromDate')}
                                            maxDate={key.toDate ? stringToDate(key.toDate, 'dd-mm-yyyy', '-') : null}
                                            selected={key.fromDate ? stringToDate(key.fromDate, 'dd-mm-yyyy', '-') : null}
                                            customInput={<MaskedInput
                                                pipe={autoCorrectedDatePipe}
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />}
                                            placeholderText='__/__/____'
                                            dateFormat='dd/MM/yyyy'
                                            required
                                        />
                                        <ReactDatePicker
                                            showYearDropdown
                                            showMonthDropdown
                                            dropdownMode="select"
                                            className="form-control form-control-sm"
                                            onSelect={e => handleChange(e, index, 'toDate')}
                                            onChange={e => handleChange(e, index, 'toDate')}
                                            minDate={key.fromDate ? stringToDate(key.fromDate, 'dd-mm-yyyy', '-') : null}
                                            selected={key.toDate ? stringToDate(key.toDate, 'dd-mm-yyyy', '-') : null}
                                            customInput={<MaskedInput
                                                pipe={autoCorrectedDatePipe}
                                                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                                                keepCharPositions={true}
                                                guide={true}
                                            />}
                                            placeholderText='__/__/____'
                                            dateFormat='dd/MM/yyyy'
                                            required
                                        />
                                        <div className="d-flex align-items-start flex-column juctify-content-between filter-x">
                                            <i onClick={() => handleClear(index)} className="fas fa-broom align-bottom d-flex align-items-center" role='button'></i>
                                            {
                                                (datePicker.length > 1) ?
                                                    <i onClick={() => handleDelete(index)} className="align-bottom fas fa-solid fa-xmark mt-2" role='button'></i> : ""

                                            }
                                            {
                                                (index < 1 && datePicker.length - 1 === index) ?
                                                    <i className="cursor-pointer fas fa-plus add-plus" onClick={() => handleAdd()}></i> : ""
                                            }
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <div className='d-flex justify-content-between'>
                        <button className='btn btn-danger media-w-btn' onClick={() => setToggle(!toggle)}> {TR(lang, "content.close")}</button>
                        <button className='btn btn-primary media-w-btn' onClick={() => handleSearch(datePicker, tempSelectedCheckbox, ids, list, tempDataType, tempDataType === 2 ? { region_id: regionId, district_id: districtId, listRegion, listDistrict } : {})}>{TR(lang, "content.search")}</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewSearchFilter;
