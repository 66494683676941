import React from 'react';
import { Line } from 'react-chartjs-2';

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

export default function AreaLineChart(props) {
  const { data } = props
  return <Line options={options} data={data} />;
}
