import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { TR } from '../../../utils/helpers';
import moment from 'moment';
import SearchService from '../../../services/SearchService';
import BarChart from '../Charts/BarChart';
import AreaChart from '../Charts/AreaChart';
import PieChart from '../Charts/PieChart';
import DoubleBarChart from '../Charts/DoubleBarChart';
import BarChartNoPadding from '../Charts/BarChartNoPadding';
import AreaLineChart from '../Charts/AreaLineChart';
const list = {
    'drug_name': 'drugID',
    'dist': 'distID',
    'sc': 'companyID',
    'mf': 'mfID',
    'df': 'dfID',
    'inn': 'innID',
    'dtg': 'dtgID',
    'dfg': 'dfgID',
    'trademark': 'trademarkID',
    'country': 'countryID',
}
const colors = ['#6610f2', '#5bcfc5', '#4d06a5', '#dc3545', '#fd7e14', '#ffc107', '#13b497', '#20c997', '#17a2b8', '#94618E', '#343a40', '#2a2a2a', '#6eadf1', '#4cb32b']
const returnObject = {
    "monthly": "totalCommonPerPriceByMonth",
    "quarterly": "totalCommonPerPriceByQuarter",
    "yearly": "totalCommonPerPriceByYear",
}
export default function NewSearchChartModal(props) {
    const {
        API,
        date,
        show,
        setShow,
        lang,
        selectedItem,
        dataID,
        dataIDList,
        selectedCheckbox,
        title,
        dataType,
        others
    } = props;

    const [data, setData] = useState([]);
    const [chartData, setChartData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isCorrectDate, setIsCorrectDate] = useState({
        monthly: false,
        quarterly: false,
        yearly: false
    });
    const [periodType, setPeriodType] = useState("monthly");
    const [chartType, setChartType] = useState("line");
    const [chartDataType, setChartDataType] = useState("Price");
    useEffect(() => {
        let temp = true;
        if (show) {
            if (false && (dataIDList[list[selectedCheckbox]].length === 0 || dataIDList[list[selectedCheckbox]].length > 10)) {
                setIsCorrectDate({
                    monthly: false,
                    quarterly: false,
                    yearly: false
                })
                temp = false;
            } else {
                for (let i = 0; i < date.length; i++) {
                    const e = date[i];
                    const result = isFullRange(e.fromDate, e.toDate)
                    temp = result.month;
                    setIsCorrectDate({
                        monthly: result.month,
                        quarterly: result.quarter,
                        yearly: result.year
                    });
                    if (!temp) break;
                }
            }
            if (temp) {
                getList(periodType)
            }
        } else {
        }
    }, [show, periodType])
    const handleChangePeriodType = (periodType) => {
        setPeriodType(periodType)
        getList(periodType)
    }
    const handleChangeChartType = (chartType) => {
        setChartType(chartType)
        convertDataToChartData(data, 0, chartType, periodType)
    }
    const handleChangeDataType = (dataType) => {
        setChartDataType(dataType)
        convertDataToChartData(data, 0, chartType, periodType)
    }


    const getList = (periodType) => {
        setLoading(true);
        const temp = {
            is_active: true,
            is_deleted: false,
            dataType,
            region_id: others.region_id ? Number(others.region_id) : null,
            district_id: others.district_id ? Number(others.district_id) : null,
            is_active: true,
            deleted: false,
            filterByDate: date,
            periodType,
            limit: 10,
            filterBy: selectedCheckbox,
            ...dataIDList,
            dataID,
            sortBy: "USD",
            sortByDesc: true,
        }
        SearchService.graphReport(temp).then(res => {
            setData(res.data.data)
            convertDataToChartData(res.data.data, 0, chartType, periodType)
            setLoading(false);
        }).catch(err => {
            setLoading(false);
        })
    }
    const convertDataToChartData = (data, period, chartType, periodType) => {
        const temp = {}
        if (periodType === "monthly") {
            temp.labels = getMonthsInRange(date[period].fromDate, date[period].toDate)
        } else if (periodType === "quarterly") {
            temp.labels = getQuartersInRange(date[period].fromDate, date[period].toDate)
        } else {
            temp.labels = getYearsInRange(date[period].fromDate, date[period].toDate)
        }
        if (chartType === "line") {
            temp.defaultFontFamily = "Poppins";
            temp.datasets = data.map((element, index) => {
                return {
                    fill: false,
                    label: element.name,
                    data: element[`period_${period + 1}`][returnObject[periodType]].map(e => e.sum_price_usd),
                    borderColor: colors[index],
                    pointBackgroundColor: "rgba(0, 0, 1128, .3)",
                }
            })
        } else {

        }
        setChartData(temp)
    }
    return (
        <Modal
            dialogClassName='modal-dialog-info'
            show={show}
            onHide={setShow}
        >
            <div className="modal-header">
                <h4 className='m-0'>{TR(lang, "table.alsoInfo")}</h4>
                <button type="button" className="btn-close" onClick={() => setShow(false)} data-dismiss="modal"><span></span></button>
            </div>

            <div className="modal-body">
                <div className="row">

                    <div className="col-md-9">
                        <div className='d-flex mb-5'>
                            {
                                date.map((key, index) => (
                                    <label key={index} className={`btn ${0 === index ? 'bg-success' : 'bg-primary'} text-white ms-2`}>
                                        {key.fromDate} : {key.toDate}
                                    </label>
                                ))
                            }
                        </div>
                        {
                            isCorrectDate[periodType] ?
                                data.length ?
                                    (
                                        chartType === "line" ? <AreaLineChart data={chartData} /> :
                                            chartType === "bar" ? <BarChart data={chartData} /> :
                                                chartType === "doubleBar" ? <DoubleBarChart data={chartData} /> :
                                                    chartType === "noPaddingBar" ? <BarChartNoPadding data={chartData} /> :
                                                        chartType === "pie" ? <PieChart data={chartData} /> : null
                                    ) : "EMPTY"
                                : "FALSE"
                        }
                    </div>
                    <div className="col-md-3">
                        <div className="row mb-4">
                            <label className="form-label fw-semibold">Filter Type</label>
                            <select className="form-select" value={periodType} onChange={(e) => handleChangePeriodType(e.target.value)}>
                                <option value="monthly">Monthly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="yearly">Yearly</option>
                            </select>
                        </div>

                        <div className="row mb-4">
                            <label className="form-label fw-semibold">Chart Type</label>
                            <select className="form-select" value={chartType} onChange={(e) => handleChangeChartType(e.target.value)}>
                                <option value="line">Line Chart</option>
                                <option value="bar">Bar Chart</option>
                                <option value="doubleBar">Dual Bar Chart</option>
                                <option value="noPaddingBar">Other Bar Chart</option>
                                <option value="pie">Pie Chart</option>
                            </select>
                        </div>

                        <div className="row">
                            <label className="form-label fw-semibold">Data Type</label>
                            <select className="form-select" value={chartDataType} onChange={(e) => handleChangeDataType(e.target.value)}>
                                <option value="Price">Price</option>
                                <option value="Quantity">Quantity</option>
                                <option value="Percentage">Percentage</option>
                            </select>
                        </div>
                    </div>
                </div>

            </div>
        </Modal>

    )
}
function isFullRange(startDate, endDate) {
    const start = moment(startDate, 'DD-MM-YYYY');
    const end = moment(endDate, 'DD-MM-YYYY');

    return {
        month: start.date() === 1 && end.date() === end.daysInMonth(),
        quarter: start.isSame(start.clone().startOf('quarter'), 'day') && end.isSame(start.clone().endOf('quarter'), 'day'),
        year:
            start.year() === end.year() && // Ensure same year
            start.isSame(start.clone().startOf('year'), 'day') && // Start of year
            end.isSame(start.clone().endOf('year'), 'day') // End of year
    }
}

function getMonthsInRange(startDate, endDate) {
    let start = moment(startDate, 'DD-MM-YYYY').startOf('month');
    let end = moment(endDate, 'DD-MM-YYYY').endOf('month');
    let months = [];

    while (start.isBefore(end) || start.isSame(end, 'month')) {
        months.push(start.format('MMMM')); // Full month name
        start.add(1, 'month');
    }

    return months;
}

function getQuartersInRange(startDate, endDate) {
    let start = moment(startDate, 'DD-MM-YYYY').startOf('quarter');
    let end = moment(endDate, 'DD-MM-YYYY').endOf('quarter');
    let quarters = [];

    while (start.isBefore(end) || start.isSame(end, 'quarter')) {
        quarters.push(`Q${start.quarter()} ${start.year()}`);
        start.add(1, 'quarter');
    }

    return quarters;
}

function getYearsInRange(startDate, endDate) {
    let start = moment(startDate, 'DD-MM-YYYY').startOf('year');
    let end = moment(endDate, 'DD-MM-YYYY').endOf('year');
    let years = [];

    while (start.isBefore(end) || start.isSame(end, 'year')) {
        years.push(start.year());
        start.add(1, 'year');
    }

    return years;
}