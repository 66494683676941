import React from "react";
import { Pie } from "react-chartjs-2";

export default function PieChart() {
    const data = {
        datasets: [
            {
                data: [45, 25, 20, 10],
                borderWidth: 0,
                backgroundColor: [
                    `${"rgba(91, 207, 193, 1)"}`,
                    `${"rgba(91, 207, 193, 0.7)"}`,
                    `${"rgba(91, 207, 193, 0.5)"}`,
                    `${"rgba(0, 0, 0, 0.07)"}`,
                ],
                hoverBackgroundColor: [
                    `${"rgba(91, 207, 193, 1)"}"}`,
                    `${"rgba(91, 207, 193, 0.7)"}`,
                    `${"rgba(91, 207, 193, 0.5)"}`,
                    `${"rgba(0, 0, 0, 0.07)"}`,
                ],
            },
        ],
        labels: ["one", "two", "three"],
    };

    const options = {
    };
    return <Pie data={data} height={150} options={options} />
};
