import React from "react";
import { Bar } from "react-chartjs-2";

export default function DoubleBarChart() {
    const data = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
        datasets: [
            {
                label: "My First dataset",
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: "rgba(64, 24, 157, 1)",
            },
            {
                label: "My Second dataset",
                data: [25, 39, 20, 31, 36, 25, 41],
                backgroundColor: "rgba(23, 123, 123, 1)",
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };
    return <Bar data={data} height={150} options={options} />
}
